/* eslint-disable react/prop-types */
import React, { Component } from "react";
import { withRouter } from "react-router";
import PropTypes from "prop-types";

class ErrorInput extends Component {
  static propTypes = {
    //match: PropTypes.object.isRequired,
    //location: PropTypes.object.isRequired,
    //history: PropTypes.object.isRequired,
    handleChangeTextInput: PropTypes.func,
    error: PropTypes.string,
  };
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        {this.props.error && (
          <p style={{ color: "red", fontSize: "10px" }}>{this.props.error}</p>
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(ErrorInput);
