import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import {
  Container,
} from "reactstrap"
import { withRouter } from "react-router";
import ListContent from "components/Sections/Places/ListContent";

class Dashboard extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>UserCode | Ovalle</title>
          </MetaTags>
          <Container fluid>
            <ListContent></ListContent>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(Dashboard);
