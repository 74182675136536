/* eslint-disable react/prop-types */
import React, { Component } from "react";
import { withRouter } from "react-router";
import { Alert } from "reactstrap";

class AlertUC extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        <Alert
          color={this.props.response_data.color}
          style={{ marginTop: "13px" }}
        >
          {this.props.response_data.message}
        </Alert>
      </React.Fragment>
    );
  }
}

export default withRouter(AlertUC);
