import PlaceAction from "components/Sections/Place/PlaceAction";
import React, { Component } from "react";
import { withRouter } from "react-router";
import MetaTags from "react-meta-tags";
import { Container } from "reactstrap";

class Place extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>UserCode | Ovalle - Place</title>
          </MetaTags>
          <Container fluid>
            <PlaceAction></PlaceAction>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(Place);
