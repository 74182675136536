import React, { Component } from "react";
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import { Redirect } from "react-router-dom";

import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
// import "./datatables.scss";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import { del, get, post, put } from "../../../helpers/ApiHelper";

class ListNews extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      id: null,
      news: [],
      redirect: false,
      response_data: [],
      modal_center: false,
      sizePerPage: 10,
      see: false,
      edit: true,
    };
  }

  async componentDidMount() {
    await this.list();
  }

    list = async () => {
      const response = await get(`/news`);
      if (response.status === 200) {
        this.setState({ news: response.data });
      }
    };

  disabledNew = async (e, row) => {
    e.preventDefault();
    let status = row.status ? false : true;
    let response = await put(`/news/${row.id}/status/${status}`);

    if (response.status === 200) {
      await this.setState(state => ({
        response_data: {
          message: response.message,
          color: "success",
        },
      }));
      setTimeout(() => {
        window.location.reload();
      // }, 2000);
      }, 500);
    } else {
      // if (!response.errors) return;

      await this.setState(state => ({
        response_data: {
          message: response.message,
          color: "danger",
        },
      }));
      for (const err of response.data) {
        let [key, value] = Object.entries(err)[0];

        this.setState(state => ({
          form_errors: {
            ...state.form_errors,
            ["message_error_" + key.split(".").pop()]: value,
          },
        }));
      }
    }
  };

  formatActionsButtons = (cell, row, rowIndex, formatExtraData) => {
    var id = row.id;
    //1:activado, 2:desactivado
    return (
      <Col>
        <Button
          color="primary"
          onClick={() => {
             this.setState({ redirect: true, id: row.id, action: "see" });
          }}
        >
          Ver
        </Button>
        &nbsp;
        <Button
          color="warning"
          onClick={() => {
            this.setState({ redirect: true, id: row.id, action: "edit" });
          }}
        >
          Editar
        </Button>
        &nbsp;
        <Button
          color={row.status ? "danger" : "success"}
          onClick={e => this.disabledNew(e, row)}
        >
          {row.status ? "Desactivar" : "Activar"}
        </Button>
      </Col>
    );
  };

  render() {
    const { ExportCSVButton } = CSVExport;
    const columns = [
      {
        hidden: true,
        dataField: "id",
        text: "ID",
        sort: true,
      },
      {
        // hidden: true,
        dataField: "title",
        text: "Titulo",
        sort: true,
        filter: textFilter({
          placeholder: "Ingrese el nombre",
        }),
      },
      {
        dataField: "created_at",
        text: "Fecha de creación",
        sort: true,
        filter: textFilter({
          placeholder: "Ingrese el descripción",
        }),
      },

      {
        dataField: "acciones",
        text: "Acciones",
        formatter: this.formatActionsButtons,
        sort: false,
      },
    ];

    const defaultSorted = [
      {
        dataField: "id",
        order: "desc",
      },
    ];

    const pageOptions = {
      sizePerPage: 10,
      custom: true,
    };

     if (this.state.redirect) {
       let url = this.state.id === null ? `/new` : `/new/id/${this.state.id}`;
       return (
         <Redirect
           push
           to={{
             pathname: url,
             action: this.state.action
           }}
         />
       );
     }

    return (
      <React.Fragment>
        <div className="page-content">
          <div className="row ">
            <div className="col-6">
              <h4>Listar</h4>
            </div>
            <div className="col-6 d-flex justify-content-end">
              <button
                className="btn btn-primary"
                onClick={() => {
                  this.setState({ redirect: true, action: "create" });
                }}
              >
                Crear
              </button>
            </div>
          </div>
          <Row className="mt-3">
            <Col className="col-12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={columns}
                    data={this.state.news}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        columns={columns}
                        data={this.state.news}
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col className="d-flex justify-content-end">
                                <div className="d-grid">
                                  <ExportCSVButton
                                    className="btn btn-outline-secondary"
                                    {...toolkitProps.csvProps}
                                  >
                                    Exportar
                                  </ExportCSVButton>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    noDataIndication="No hay datos"
                                    keyField={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    filter={filterFactory()}
                                    filterPosition="top"
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }
}

export default ListNews;
