/* eslint-disable react/prop-types */
import React, { Component } from "react";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { AvField } from "availity-reactstrap-validation";
import { Col, FormGroup, Label } from "reactstrap";
import ErrorInput from "./ErrorInput";

class StringInput extends Component {
  static propTypes = {
    //match: PropTypes.object.isRequired,
    //location: PropTypes.object.isRequired,
    //history: PropTypes.object.isRequired,
    handleChangeTextInput: PropTypes.func,
    edit: PropTypes.bool,
    error: PropTypes.array,
    data: PropTypes.array,
  };
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        <FormGroup className="row mb-4">
          <Label
            className="col-sm-2 col-form-label"
            htmlFor={this.props.data.label}
          >
            {this.props.data.title}
          </Label>
          <Col className="col-sm-9 col-lg-10">
             <AvField
             name={this.props.data.label}
              onChange={e => this.props.handleChangeTextInput(e)}
              value={this.props.data?.value ? this.props.data.value : null}
              className="form-control"
              placeholder={`Ingrese ${this.props.data.title}`}
              type={this.props.data.type}
              //Invested securities
              // errorMessage="Ingrese un nombre"
              disabled={
                this.props.edit === true || this.props.edit === undefined
                  ? false
                  : true
              }
              //Normal values
              // required={
              //   this.props.edit === true || this.props.edit === undefined
              //     ? true
              //     : false
              // }
              // validate={{
              //   required: {
              //     value: true,
              //     errorMessage: `Ingrese ${this.props.data.title}`,
              //   },
              // }} 
            />
            {this.props.error && <ErrorInput error={this.props.error} />}
          </Col>
        </FormGroup>
      </React.Fragment>
    );
  }
} 

export default withRouter(StringInput);
