/* eslint-disable react/prop-types */
import React, { Component } from "react";
import { withRouter } from "react-router";
import { Button } from "reactstrap";

class RutInput extends Component {
  render() {
    return (
      <Button
        color={this.props.color ? this.props.color : "primary"}
        size="lg"
        block
        disabled={this.props.disabled === true ? true : false}
        type="submit"
      >
        {this.props.label ? this.props.label : "Guardar"}
      </Button>
    );
  }
}

export default withRouter(RutInput);
