/* eslint-disable react/prop-types */
import React, { Component } from "react";
import { Row, Col, Card, CardBody, Button, FormGroup, Label, Input } from "reactstrap";
import { Redirect } from "react-router-dom";
import { withRouter } from "react-router";

import Autocomplete from "react-google-autocomplete";

// availity-reactstrap-validation
import { AvField, AvForm } from "availity-reactstrap-validation";
// import "./datatables.scss";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import { del, get, post, put, API_URL } from "../../../helpers/ApiHelper";
import StringInput from "components/Form/StringInput";
import SelectInput from "components/Form/SelectInput";
import SaveButton from "components/Form/SaveButton";
import AlertUC from "components/Form/AlertUC";
import { element } from "prop-types";

class PlaceAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      form_data: {
        status: true,
      },
      delete_images: [],
      services: [
        {
          name: [],
        },
      ],
      placeimages: [
        {
          name: "",
          pos: "",
          file: {
            data: "",
            type: "",
          },
        },
      ],
      categoriesOptions: [],
      subcategoriesOptions: [],
      subdivisionsOptions: [],
      response_data: null,
      form_errors: [],
      modal_center: false,
      action: "create",
    };
  }

  async componentDidMount() {
    // const location = this.props.location ;
    await this.setState({ action: this.props.location.action });
    // this.state.action !== "create" && this.list();
    const { match } = this.props;
    await this.setState(state => ({
      form_data: {
        id: match.params.id,
      },
    }));
    this.state.form_data.id && (await this.getPlace());
    await this.getSubdivisions();
    await this.getCategories();
    
  }

  /**Request */
  async getCategories() {
    const response = await get(`/categories`);
    if (response.status === 200) {
      for (var n = 0; n < response.data.length; n++) {
        response.data[n].label = response.data[n].name;
        response.data[n].value = response.data[n].id;
      }
      this.setState({ categoriesOptions: response.data });
    }
  }

  async getSubcategories(category_id) {
    let lastItem =
      category_id.length > 1
        ? category_id[category_id.length - 1]
        : category_id; 
    const response = await get(`/categories/${lastItem}`);
    let subcategories = [...this.state.subcategoriesOptions];
    if (response.status === 200) {
      for (var n = 0; n < response.data.subcategories.length; n++) {
        response.data.subcategories[n].label =
          response.data.subcategories[n].name;
        response.data.subcategories[n].value =
          response.data.subcategories[n].id;
      }
      subcategories = [...subcategories, ...response.data.subcategories];
      await this.setState({ subcategoriesOptions: subcategories });
    }
  }
  
  async getSubdivisions() {
    const response = await get(`/subdivisions/`);
    if (response.status === 200) {
      for (var n = 0; n < response.data.length; n++) {
        response.data[n].label =
          response.data[n].name;
        response.data[n].value =
          response.data[n].id;
      }
      await this.setState({ subdivisionsOptions: response.data });
    }
  }

  async getPlace() {
    const response = await get(`/places/${this.state.form_data.id}`);
    console.log(response);
     for (var n = 0; n < response.data.subcategories.length; n++) {
        response.data.subcategories[n].label =
          response.data.subcategories[n].name;
        response.data.subcategories[n].value =
          response.data.subcategories[n].id;
      }
     for (var n = 0; n < response.data.subdivisions.length; n++) {
       response.data.subdivisions[n].label = response.data.subdivisions[n].name;
       response.data.subdivisions[n].value = response.data.subdivisions[n].id;
     }
      let subcategoriesSelected = response.data.subcategories.map((el)=>{
        return el.id
      });
      let subdivisionsSelected = response.data.subdivisions.map(el => {
        return el.id;
      });
      let categoriesSelected = response.data.subcategories.map((el)=>{
        return el.category_id;
      });
      var uniqueCategoriesSelected = [...new Set(categoriesSelected)];
    if (response.status === 200) {
      response.data.placeimages.map(el => {
        el.image_id = el.id;
      });
      this.setState({ form_data: response.data });
       await this.setState(state => ({
         form_data: {
           ...state.form_data,
           subcategories_ids: subcategoriesSelected,
           categories_ids: uniqueCategoriesSelected,
           subdivisions_id: subdivisionsSelected,
         },
       }));
      // this.setState({ subcategoriesOptions: response.data.subcategories });
      this.setState({
        services:
          response.data.services.length > 0 ? response.data.services : [...this.state.services],
        placeimages: response.data.placeimages,
      });
    }
    uniqueCategoriesSelected.forEach(async el => {
      await this.getSubcategories(el);
    });
    await this.getSubdivisions();
    console.log(this.state.services)
  }

  

  /**Inpurts text */
  handleChangeTextInput = async event => {
    let value = event.target.value,
      name = event.target.name;
    await this.setState(state => ({
      form_data: {
        ...state.form_data,
        [name]: value,
      },
    }));
  };

  /**Select */
  handleChangeSelectInput = async (event, name) => {
    let value = Array.isArray(event)
      ? event.map(item => item.value)
      : event.value;
      name === "categories_ids" && this.getSubcategories(value);
      value == 17 && this.getSubdivisions();
    await this.setState(state => ({
      form_data: {
        ...state.form_data,
        [name]: value,
      },
    }));
  };

  validateSelectInput = async (event, name, value) => {
    await this.setState(state => ({
      form_errors: {
        ...state.form_errors,
        ["message_error_" + name]:
          value === -1 || value === null || value === undefined
            ? "Este campo es necesario"
            : null,
      },
    }));
  };

  /**Services */
  handleChangeService = async (event, indexService) => {
    const services = [...this.state.services];
    services[indexService][event.target.name] = event.target.value;
    await this.setState({ services: services });
  };

  addCategory = indexService => {
    const service = {
      name: null,
    };
    this.setState({ services: [...this.state.services, service] });
  };

  removeService = indexService => {
    const service = [...this.state.services];
    service.splice(indexService, 1);
    this.setState({ services: service });
  };

  /**Place Image */

  encodeFile = file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let encoded = reader.result.toString().replace(/^data:(.*,)?/, "");
        if (encoded.length % 4 > 0) {
          encoded += "=".repeat(4 - (encoded.length % 4));
        }
        resolve(encoded);
      };
      reader.onerror = error => reject(error);
    });
  };

  handleChangePlaceImage = async (event, indexPlaceImage) => {
    const placeimages = [...this.state.placeimages];
    let img = event.target.files ? event.target?.files[0] : null;
    if (img) {
      let file = {
        data: await this.encodeFile(img),
        type: img.type.split("/")[1],
      };
      Object.assign(img, {
        preview: URL.createObjectURL(img),
        formattedSize: this.formatBytes(img.size),
      });

      placeimages[indexPlaceImage]["file"] = file;
      placeimages[indexPlaceImage]["preview"] = URL.createObjectURL(img);
      // placeimages[indexPlaceImage]["data"] = data;
    }
    placeimages[indexPlaceImage][event.target.name] = event.target.value;
    await this.setState({ placeimages: placeimages });
  };

  addPlaceImage = indexPlaceImage => {
    const placeimage = {
      name: "",
      pos: "",
      is_new: true,
      // image_id: this.state.formdata
      file: {
        data: "",
        type: "",
      },
    };
    this.setState({ placeimages: [...this.state.placeimages, placeimage] });

  };

  removePlaceImage = async (e, indexPlaceImage) => {
    // var smp=[];
    var smp = [...this.state.delete_images];
    console.log(smp);
    const placeimage = [...this.state.placeimages];
    smp.push(placeimage[indexPlaceImage].id);
    console.log(smp);
    let placeimageindex = placeimage[indexPlaceImage].id;
    placeimage[indexPlaceImage].id ?
      await this.setState(state => ({
        delete_images: smp,
      })) : [];
    placeimage.splice(indexPlaceImage, 1);
    this.setState({ placeimages: placeimage });

    // this.setState({ delete_images: placeimage });
    console.log(this.state.delete_images,  smp);
  };

  /**
   * Formats the size
   */
  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  /**Submit form */
  async handleValidSubmit(event, values) {
    event.preventDefault();
    let data = [];
    
    
    data =
      this.state.services[0].name.length > 0
        ? {
            id: this.state.form_data.id,
            title: this.state.form_data.title,
            description: this.state.form_data.description,
            address: this.state.form_data.address,
            owner: this.state.form_data.owner,
            business_hours: this.state.form_data.business_hours,
            email: this.state.form_data.email,
            phone: parseInt(this.state.form_data.phone),
            facebook: this.state.form_data.facebook,
            instagram: this.state.form_data.instagram,
            url: this.state.form_data.url,
            map_latitude: parseFloat(this.state.form_data.map_latitude),
            map_longitude: parseFloat(this.state.form_data.map_longitude),
            status: true,
            services: [...this.state.services],
            subcategories_ids: this.state.form_data.subcategories_ids,
            subdivisions_ids: this.state.form_data.subdivisions_id,
            placeimages: [...this.state.placeimages],
            delete_images: [...this.state.delete_images],
          }
        : {
            id: this.state.form_data.id,
            title: this.state.form_data.title,
            description: this.state.form_data.description,
            address: this.state.form_data.address,
            owner: this.state.form_data.owner,
            business_hours: this.state.form_data.business_hours,
            email: this.state.form_data.email,
            phone: parseInt(this.state.form_data.phone),
            facebook: this.state.form_data.facebook,
            instagram: this.state.form_data.instagram,
            url: this.state.form_data.url,
            map_latitude: parseFloat(this.state.form_data.map_latitude),
            map_longitude: parseFloat(this.state.form_data.map_longitude),
            status: true,
            subcategories_ids: this.state.form_data.subcategories_ids,
            subdivisions_ids: this.state.form_data.subdivisions_id,
            placeimages: [...this.state.placeimages],
            delete_images: [...this.state.delete_images],
          };
          console.log(data);

    let response =
      this.state.action === "create" && this.state.form_data.id === undefined
        ? await post(`/places`, data)
        : await put(`/places/${this.state.form_data.id}`, data);
    if (response.status === 200) {
      await this.setState(state => ({
        response_data: {
          message: response.message,
          color: "success",
        },
        form_children: true,
        new_proxy: response.data,
      }));
    } else {
      await this.setState(state => ({
        response_data: {
          message: response.message,
          color: "danger",
        },
      }));
      for (const err of response.data) {
        let [key, value] = Object.entries(err)[0];

        this.setState(state => ({
          form_errors: {
            ...state.form_errors,
            ["message_error_" + key.split(".").pop()]: value,
          },
        }));
      }
    }
  }

  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          push
          to={{
            pathname: `/place`,
            edit: true,
          }}
        />
      );
    }

    return (
      <React.Fragment>
        <AvForm
          className="form-horizontal"
          // onValidSubmit={e => this.handleValidSubmit(e)}
        >
          <StringInput
            handleChangeTextInput={e => this.handleChangeTextInput(e)}
            data={{
              label: "title",
              title: "Titulo",
              type: "text",
              value:
                this.state.action !== "create"
                  ? this.state.form_data.title
                  : null,
            }}
            edit={this.state.action !== "see" ? true : false}
            error={this.state.form_errors?.message_error_title}
          />
          <StringInput
            handleChangeTextInput={e => this.handleChangeTextInput(e)}
            data={{
              label: "description",
              title: "Description",
              type: "textarea",
              value:
                this.state.action !== "create"
                  ? this.state.form_data.description
                  : null,
            }}
            edit={this.state.action !== "see" ? true : false}
            error={this.state.form_errors?.message_error_description}
          />
          <StringInput
            handleChangeTextInput={e => this.handleChangeTextInput(e)}
            data={{
              label: "owner",
              title: "Dueño",
              type: "text",
              value:
                this.state.action !== "create"
                  ? this.state.form_data.owner
                  : null,
            }}
            edit={this.state.action !== "see" ? true : false}
            error={this.state.form_errors?.message_error_owner}
          />
          <StringInput
            handleChangeTextInput={e => this.handleChangeTextInput(e)}
            data={{
              label: "address",
              title: "Dirección",
              type: "text",
              value:
                this.state.action !== "create"
                  ? this.state.form_data.address
                  : null,
            }}
            edit={this.state.action !== "see" ? true : false}
            error={this.state.form_errors?.message_error_address}
          />
          <StringInput
            handleChangeTextInput={e => this.handleChangeTextInput(e)}
            data={{
              label: "business_hours",
              title: "Horario",
              type: "textarea",
              value:
                this.state.action !== "create"
                  ? this.state.form_data.business_hours
                  : null,
            }}
            edit={this.state.action !== "see" ? true : false}
            error={this.state.form_errors?.message_error_business_hours}
          />
          <StringInput
            handleChangeTextInput={e => this.handleChangeTextInput(e)}
            data={{
              label: "email",
              title: "Email",
              type: "email",
              value:
                this.state.action !== "create"
                  ? this.state.form_data.email
                  : null,
            }}
            edit={this.state.action !== "see" ? true : false}
            error={this.state.form_errors?.message_error_email}
          />
          <StringInput
            handleChangeTextInput={e => this.handleChangeTextInput(e)}
            data={{
              label: "phone",
              title: "Telefono",
              type: "number",
              value:
                this.state.action !== "create"
                  ? this.state.form_data.phone
                  : null,
            }}
            edit={this.state.action !== "see" ? true : false}
            error={this.state.form_errors?.message_error_phone}
          />
          <StringInput
            handleChangeTextInput={e => this.handleChangeTextInput(e)}
            data={{
              label: "facebook",
              title: "Facebook",
              type: "text",
              value:
                this.state.action !== "create"
                  ? this.state.form_data.facebook
                  : null,
            }}
            edit={this.state.action !== "see" ? true : false}
            error={this.state.form_errors?.message_error_facebook}
          />
          <StringInput
            handleChangeTextInput={e => this.handleChangeTextInput(e)}
            data={{
              label: "instagram",
              title: "Instagram",
              type: "text",
              value:
                this.state.action !== "create"
                  ? this.state.form_data.instagram
                  : null,
            }}
            edit={this.state.action !== "see" ? true : false}
            error={this.state.form_errors?.message_error_instagram}
          />
          <StringInput
            handleChangeTextInput={e => this.handleChangeTextInput(e)}
            data={{
              label: "url",
              title: "URL",
              type: "text",
              value:
                this.state.action !== "create"
                  ? this.state.form_data.url
                  : null,
            }}
            edit={this.state.action !== "see" ? true : false}
            error={this.state.form_errors?.message_error_url}
          />
          {/* <FormGroup className="mb-4">
            <Label htmlFor="address" sm={2}>
              Dirección
            </Label>
            <Autocomplete
              className={
                this.state.form_errors["message_error_address"]
                  ? "form-control is-invalid"
                  : "form-control"
              }
              id="address"
              name="address"
              placeholder="Ingrese una dirección"
              type="text"
              {...seach_place_props}
              onPlaceSelected={place => this.handlePlaceSelected(place)}
              onChange={e => this.handlePlaceChanged(e)}
              onBlur={e => this.ValidateTextInput(e)}
              value={this.state.address}
              disabled={!this.state.edit}
            /> */}
          {/* <Form.Control.Feedback type="invalid">
              {this.state.form_errors["message_error_address"]}
            </Form.Control.Feedback> */}
          {/* </FormGroup> */}
          <StringInput
            handleChangeTextInput={e => this.handleChangeTextInput(e)}
            data={{
              label: "map_latitude",
              title: "Latitud",
              type: "number",
              value:
                this.state.action !== "create"
                  ? this.state.form_data.map_latitude
                  : null,
            }}
            edit={this.state.action !== "see" ? true : false}
            error={this.state.form_errors?.message_error_map_latitude}
          />
          <StringInput
            handleChangeTextInput={e => this.handleChangeTextInput(e)}
            data={{
              label: "map_longitude",
              title: "Longitud",
              type: "number",
              value:
                this.state.action !== "create"
                  ? this.state.form_data.map_longitude
                  : null,
            }}
            edit={this.state.action !== "see" ? true : false}
            error={this.state.form_errors?.message_error_map_longitude}
          />
          <SelectInput
            handleChangeSelectInput={e =>
              this.handleChangeSelectInput(e, "categories_ids")
            }
            validateSelectInput={e =>
              this.validateSelectInput(
                e,
                "categories_ids",
                this.state.form_data.categories_ids
              )
            }
            data={{
              title: "Categorias",
              label: "categories_ids",
              options: this.state.categoriesOptions,
              value: this.state.form_data?.categories_ids,
              isMulti: true,
            }}
            error={this.state.form_errors.message_error_categories_ids}
            edit={this.state.action !== "see" ? true : false}
          />
          <SelectInput
            handleChangeSelectInput={e =>
              this.handleChangeSelectInput(e, "subcategories_ids")
            }
            validateSelectInput={e =>
              this.validateSelectInput(
                e,
                "subcategories_ids",
                this.state.form_data.subcategories_ids
              )
            }
            data={{
              title: "Subcategorias",
              label: "subcategories_ids",
              options: this.state.subcategoriesOptions,
              value: this.state.form_data?.subcategories_ids,
              isMulti: true,
            }}
            error={this.state.form_errors.message_error_subcategories_ids}
            edit={
              this.state.action !== "see" ||
              this.state.form_data.subcategories_ids == 17
                ? true
                : false
            }
          />
          {this.state.form_data.subcategories_ids == 17 && (
            <SelectInput
              handleChangeSelectInput={e =>
                this.handleChangeSelectInput(e, "subdivisions_id")
              }
              validateSelectInput={e =>
                this.validateSelectInput(
                  e,
                  "subdivisions_id",
                  this.state.form_data.subdivisions_id
                )
              }
              data={{
                title: "Subdivisiones",
                label: "subdivisions_id",
                options: this.state?.subdivisionsOptions,
                value: this.state.form_data?.subdivisions_id,
                isMulti: true,
              }}
              error={this.state.form_errors.message_error_subdivisions_id}
              edit={this.state.action !== "see" ? true : false}
            />
          )}
          {this.state.services?.map((service, indexService) => {
            return (
              <FormGroup key={indexService} className="row mb-4 ">
                <div className="row mb-1 ">
                  {" "}
                  {/*bg-secondary**/}
                  <Row className="mt-2">
                    <Label className="col-sm-2 col-form-label " htmlFor="rut">
                      Servicios
                    </Label>
                    <Label className="col-sm-2 col-form-label " htmlFor="rut">
                      Nombre
                    </Label>
                    <Col className="col-sm-6 ">
                      <Input
                        type="text"
                        name="name"
                        data-testid="nameC"
                        // disabled={this.props.edit === false ? true : false}
                        disabled={this.state.action !== "see" ? false : true}
                        value={service.name}
                        placeholder="Escriba un nombre"
                        onChange={event =>
                          this.handleChangeService(event, indexService)
                        }
                      />
                    </Col>
                    {this.state.services.length - 1 === indexService && (
                      <Col className="col-1 ">
                        <div className="d-grid">
                          <button
                            // disabled={this.props.edit === false ? true : false}
                            disabled={
                              this.state.action !== "see" ? false : true
                            }
                            className="btn btn-primary btn-block"
                            onClick={() => this.addCategory(indexService)}
                          >
                            <i className="fa fa-plus" aria-hidden="true"></i>
                          </button>
                        </div>
                      </Col>
                    )}
                    {this.state.services.length > 1 && (
                      <Col className="col-1 ">
                        <div className="d-grid">
                          <button
                            disabled={
                              this.state.action !== "see" ? false : true
                            }
                            // disabled={this.props.edit === false ? true : false}
                            className="btn btn-danger btn-block"
                            onClick={() => this.removeService(indexService)}
                          >
                            <i className="fa fa-minus" aria-hidden="true"></i>
                          </button>
                        </div>
                      </Col>
                    )}
                  </Row>
                </div>
              </FormGroup>
            );
          })}
          {this.state.placeimages.map((placeimage, indexPlaceimages) => {
            return (
              <FormGroup key={indexPlaceimages} className="row mb-4 ">
                <div className="row mb-1 ">
                  {" "}
                  {/*bg-secondary*/}
                  <Row className="mt-2">
                    <Label className="col-sm-2 col-form-label " htmlFor="rut">
                      Imagenes
                    </Label>
                    <Label className="col-sm-2 col-form-label " htmlFor="rut">
                      Nombre
                    </Label>
                    <Col className="col-sm-6 ">
                      <Input
                        type="text"
                        name="name"
                        data-testid="nameI"
                        // disabled={this.props.edit === false ? true : false}
                        disabled={this.state.action !== "see" ? false : true}
                        value={placeimage.name}
                        placeholder="Escriba un nombre"
                        onChange={event =>
                          this.handleChangePlaceImage(event, indexPlaceimages)
                        }
                      />
                    </Col>
                    {this.state.placeimages.length - 1 === indexPlaceimages && (
                      <Col className="col-1 ">
                        <div className="d-grid">
                          <button
                            // disabled={this.props.edit === false ? true : false}
                            disabled={
                              this.state.action !== "see" ? false : true
                            }
                            className="btn btn-primary btn-block"
                            onClick={() => this.addPlaceImage(indexPlaceimages)}
                          >
                            <i className="fa fa-plus" aria-hidden="true"></i>
                          </button>
                        </div>
                      </Col>
                    )}
                    {this.state.placeimages.length > 1 && (
                      <Col className="col-1 ">
                        <div className="d-grid">
                          <button
                            disabled={
                              this.state.action !== "see" ? false : true
                            }
                            // disabled={this.props.edit === false ? true : false}
                            className="btn btn-danger btn-block"
                            onClick={(e) =>
                              this.removePlaceImage(e, indexPlaceimages)
                            }
                          >
                            <i className="fa fa-minus" aria-hidden="true"></i>
                          </button>
                        </div>
                      </Col>
                    )}
                  </Row>
                  <Row className="mt-2 mb-2">
                    <Label
                      className="col-sm-2 col-form-label"
                      htmlFor=""
                    ></Label>
                    <Label
                      className="col-sm-3 col-lg-2 col-form-label"
                      htmlFor="icon"
                    >
                      Posición
                    </Label>
                    <Col className="col-sm-6">
                      <Input
                        className="form-control"
                        disabled={this.state.action !== "see" ? false : true}
                        type="number"
                        name="pos"
                        id="pos"
                        value={placeimage?.pos}
                        placeholder="Ingresa una posición"
                        onChange={event =>
                          this.handleChangePlaceImage(event, indexPlaceimages)
                        }
                      />
                    </Col>
                  </Row>
                  <Row className="mt-2 mb-2">
                    <Label
                      className="col-sm-2 col-form-label"
                      htmlFor=""
                    ></Label>
                    <Label
                      className="col-sm-3 col-lg-2 col-form-label"
                      htmlFor="icon"
                    >
                      Imagen
                    </Label>
                    <Col className="col-sm-6">
                      <Input
                        className="form-control"
                        disabled={this.state.action !== "see" ? false : true}
                        type="file"
                        name="icon"
                        id="icon"
                        accept="image/*"
                        value={placeimage?.icon}
                        placeholder="Seleccionar una imagen"
                        onChange={event =>
                          this.handleChangePlaceImage(event, indexPlaceimages)
                        }
                      />
                    </Col>
                    <Col className="col-2">
                      {placeimage && (
                        <div className="d-grid">
                          <img
                            style={{
                              width: "50px",
                              height: "50px",
                              objectFit: "cover",
                              objectPosition: "100% 20%",
                            }}
                            data-dz-thumbnail=""
                            height="200"
                            className="avatar-md rounded bg-light"
                            alt={placeimage.icon?.name}
                            src={
                              placeimage?.preview
                                ? placeimage.preview
                                : API_URL + placeimage.path
                            }
                          />
                        </div>
                      )}
                    </Col>
                  </Row>
                </div>
              </FormGroup>
            );
          })}
          {this.state.response_data && (
            <Row>
              <Col className="col">
                <div className="mt-3 d-grid">
                  <AlertUC response_data={this.state.response_data} />
                </div>
              </Col>
            </Row>
          )}
          {this.state.action !== "see" && (
            <Row>
              <Col className="col">
                <div className="mt-3 d-grid">
                   <Button
                  color="primary"
                  size="lg"
                  block
                  onClick={(e) =>
                              this.handleValidSubmit(e)
                            }
                  type="submit"
                >
                 Guardar
                </Button>
                  {/* <SaveButton></SaveButton> */}
                </div>
              </Col>
            </Row>
          )}
        </AvForm>
      </React.Fragment>
    );
  }
}

export default withRouter(PlaceAction);
