/* eslint-disable react/prop-types */
import React, { Component } from "react";
import { withRouter } from "react-router";
import { AvField } from "availity-reactstrap-validation";

class EmailInput extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        <AvField
          name="password"
          label="Password"
          onChange={e => this.props.handleChangeTextInput(e)}
          // value="123456"
          type="password"
          required
          errorMessage="Ingrese su contraseña"
          placeholder="Ingrese su contraseña"
        />
      </React.Fragment>
    );
  }
}

export default withRouter(EmailInput);
