import React, { Component } from "react";
import PropTypes from "prop-types";

import { Alert, Card, CardBody, Col, Container, Row } from "reactstrap";

// Redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

//Social Media Imports
import { GoogleLogin } from "react-google-login";
// import TwitterLogin from "react-twitter-auth"
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

//Import config
import { facebook, google } from "../../config";

// availity-reactstrap-validation
import { AvField, AvForm } from "availity-reactstrap-validation";

// actions
import { apiError, loginUser, socialLogin } from "../../store/actions";



//Helpers
import { post } from "../../helpers/ApiHelper";
import EmailInput from "components/Form/EmailInputLogin";
import PasswordInput from "components/Form/PasswordInputLogin";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form_data: [],
      loading: true,
    };

    // handleValidSubmit
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
  }

  async componentDidMount() {
    const token = JSON.parse(localStorage.getItem("token"));
    const auth = JSON.parse(localStorage.getItem("authUser"));
    if (token !== null && auth !== null) {
      window.location.href = "/dashboard";
    }
    // let user = JSON.parse(localStorage.getItem("user"));
    // await this.setState({ user: user loading: false });
    this.props.apiError("");
  }

  // handleValidSubmit
  async handleValidSubmit(event, values) {
    event.preventDefault();
    let data = {
      email: this.state.form_data.email,
      password: this.state.form_data.password,
    };
    const response = await post("/login", data);
    if (response.status === 200 || response.status === 401) {
      // console.log(response.data.token);
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("authUser", JSON.stringify(response.data.userDB));
      window.location = "/dashboard";
      // if (response.data.userDB.Client !== null) {
      //   window.location = "/plans";
      // } else {
      // }
    } else {
      this.setState({
        errorMessage: response.message,
      });
    }
  }

  handleChangeTextInput = async event => {
    await this.setState(state => ({
      form_data: {
        ...state.form_data,
        [event.target.name]: event.target.value,
      },
    }));
  };

  handleCheckbox = event => {
    this.setState(state => ({
      form_data: {
        ...state.form_data,
        [event.target.name]: event.target.checked,
      },
    }));
  };

  render() {
    return (
      <React.Fragment>
        <div className="home-btn d-none d-sm-block"></div>
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden login_fondo">
                  <div className="bg-login-footer">
                    <Row>
                      <Col className="col-7">
                        <div className="text-success p-5"></div>
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div className="auth-logo">
                      <Link to="/" className="auth-logo-light">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            {/* <img
                              src={lightlogo}
                              alt=""
                              className="rounded-circle"
                              height="34"
                            /> */}
                          </span>
                        </div>
                      </Link>
                      <Link to="/" className="auth-logo-dark">
                        <div className="avatar-md profile-user-wid mb-4">
                          {/* <span
                            style={{ background: "#7cac47" }}
                            className="avatar-title rounded-circle"
                          >
                            <img
                              // src={logo}
                              alt=""
                              className="rounded-circle"
                              height="72"
                            />
                          </span> */}
                        </div>
                      </Link>
                    </div>
                    <div className="p-2">
                      <AvForm
                        className="form-horizontal"
                        onValidSubmit={e => this.handleValidSubmit(e)}
                      >
                        {this.state.errorMessage && (
                          <Alert color="danger">
                            {this.state.errorMessage}
                          </Alert>
                        )}
                        <div className="mb-3">
                          <EmailInput
                            user={this.state.user}
                            handleChangeTextInput={e =>
                              this.handleChangeTextInput(e)
                            }
                          />
                        </div>
                        <div className="mb-3">
                          <PasswordInput
                            user={this.state.user}
                            handleChangeTextInput={e =>
                              this.handleChangeTextInput(e)
                            }
                          />
                        </div>
                        {/* <div className="form-check">
                          <input
                            type="checkbox"
                            checked={
                              this.state.user ? this.state.user.check : false
                            }
                            className="form-check-input"
                            id="remember_me"
                            name="remember_me"
                            onClick={e => this.handleCheckbox(e)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="remember_me"
                          >
                            Recordar usuario
                          </label>
                        </div> */}
                        <div className="mt-3 d-grid">
                          <button
                            className="btn bg-warning text-blck btn-block "
                            type="submit"
                          >
                            Ingresar
                          </button>
                        </div>
                        <div className="mt-4 text-center text-white">
                          <Link
                            to="/forgot-password"
                            className="btn text-white"
                            id="login_forgotpassword"
                          >
                            <i className="mdi mdi-lock me-1 text-white" />{" "}
                            Olvidó su contraseña?
                          </Link>
                        </div>
                      </AvForm>
                    </div>
                    <div className="mt-5 text-center">
                      <p>
                        © {new Date().getFullYear()}{" "}
                        <a href="https://usercode.cl/">UserCode</a>. todos los
                        derechos reservados.
                      </p>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

Login.propTypes = {
  apiError: PropTypes.any,
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  socialLogin: PropTypes.func,
};

const mapStateToProps = state => {
  const { error } = state.Login;
  return { error };
};

export default withRouter(
  connect(mapStateToProps, { loginUser, apiError, socialLogin })(Login)
);
