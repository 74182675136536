/* eslint-disable react/prop-types */
import React, { Component } from "react";
import { Row, Col, Card, CardBody, Button, FormGroup, Label, Input } from "reactstrap";
import { Redirect } from "react-router-dom";
import { withRouter } from "react-router";

import Autocomplete from "react-google-autocomplete";

// availity-reactstrap-validation
import { AvField, AvForm } from "availity-reactstrap-validation";
// import "./datatables.scss";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import { del, get, post, put, API_URL } from "../../../helpers/ApiHelper";
import StringInput from "components/Form/StringInput";
import SelectInput from "components/Form/SelectInput";
import SaveButton from "components/Form/SaveButton";
import AlertUC from "components/Form/AlertUC";
import { element } from "prop-types";

class NewAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      form_data: {
        status: true,
      },
      delete_images: [],
      newsimages: [
        {
          name: "",
          pos: "",
          file: {
            data: "",
            type: "",
          },
        },
      ],
      response_data: null,
      form_errors: [],
      modal_center: false,
      action: "create",
    };
  }

  async componentDidMount() {
    // const location = this.props.location ;
    await this.setState({ action: this.props.location.action });
    // this.state.action !== "create" && this.list();
    const { match } = this.props;
    console.log(match.params.id);
    await this.setState(state => ({
      form_data: {
        id: match.params.id,
      },
    }));
    await this.getNew();
  }

  /**Request */
  async getNew() {
    const response = await get(`/news/${this.state.form_data.id}`);
    console.log(response);
    if (response.status === 200) {
      response.data.newsimages.map(el => {
        el.image_id = el.id;
      });
      this.setState({ form_data: response.data });
      await this.setState(state => ({
        form_data: {
          ...state.form_data,
        },
        newsimages: response.data.newsimages,
      }));
      // this.setState({ subcategoriesOptions: response.data.subcategories });
    }
  }

  /**Inpurts text */
  handleChangeTextInput = async event => {
    let value = event.target.value,
      name = event.target.name;
    await this.setState(state => ({
      form_data: {
        ...state.form_data,
        [name]: value,
      },
    }));
  };

  /**Place Image */

  encodeFile = file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let encoded = reader.result.toString().replace(/^data:(.*,)?/, "");
        if (encoded.length % 4 > 0) {
          encoded += "=".repeat(4 - (encoded.length % 4));
        }
        resolve(encoded);
      };
      reader.onerror = error => reject(error);
    });
  };

  handleChangePlaceImage = async (event, indexNewImage) => {
    const newsimages = [...this.state.newsimages];
    let img = event.target.files ? event.target?.files[0] : null;
    if (img) {
      let file = {
        data: await this.encodeFile(img),
        type: img.type.split("/")[1],
      };
      Object.assign(img, {
        preview: URL.createObjectURL(img),
        formattedSize: this.formatBytes(img.size),
      });

      newsimages[indexNewImage]["file"] = file;
      newsimages[indexNewImage]["preview"] = URL.createObjectURL(img);
      // newsimages[indexNewImage]["data"] = data;
    }
    newsimages[indexNewImage][event.target.name] = event.target.value;
    await this.setState({ newsimages: newsimages });
  };

  addPlaceImage = indexNewImage => {
    const newimage = {
      name: "",
      pos: "",
      is_new: true,
      // image_id: this.state.formdata
      file: {
        data: "",
        type: "",
      },
    };
    this.setState({ newsimages: [...this.state.newsimages, newimage] });
  };

  removePlaceImage = async indexNewImage => {
    const newimage = [...this.state.newsimages];
    let arra_delete_image = [...this.state.delete_images];
    let newimageindex = newimage[indexNewImage];
    arra_delete_image = newimageindex.id
      ? arra_delete_image.push(newimageindex.id)
      : arra_delete_image;
    newimageindex.id !== undefined &&
      (await this.setState({
        delete_images: arra_delete_image,
      }));
    newimage.splice(indexNewImage, 1);
    this.setState({ newsimages: newimage });

    // this.setState({ delete_images: newimage });
  };

  /**
   * Formats the size
   */
  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  /**Submit form */
  async handleValidSubmit(event, values) {
    event.preventDefault();
    let data = [];

    data =
       {
            id: this.state.form_data.id,
            title: this.state.form_data.title,
            text: this.state.form_data.text,
            status: true,
            newsimages: [...this.state.newsimages],
            delete_images: [...this.state.delete_images],
          }
    console.log(data);

    let response =
      this.state.action === "create" && this.state.form_data.id === undefined
        ? await post(`/news`, data)
        : await put(`/news/${this.state.form_data.id}`, data);
    if (response.status === 200) {
      await this.setState(state => ({
        response_data: {
          message: response.message,
          color: "success",
        },
        form_children: true,
        new_proxy: response.data,
      }));
    } else {
      await this.setState(state => ({
        response_data: {
          message: response.message,
          color: "danger",
        },
      }));
      for (const err of response.data) {
        let [key, value] = Object.entries(err)[0];

        this.setState(state => ({
          form_errors: {
            ...state.form_errors,
            ["message_error_" + key.split(".").pop()]: value,
          },
        }));
      }
    }
  }

  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          push
          to={{
            pathname: `/place`,
            edit: true,
          }}
        />
      );
    }

    return (
      <React.Fragment>
        <AvForm
          className="form-horizontal"
          onValidSubmit={e => this.handleValidSubmit(e)}
        >
          <StringInput
            handleChangeTextInput={e => this.handleChangeTextInput(e)}
            data={{
              label: "title",
              title: "Titulo",
              type: "text",
              value:
                this.state.action !== "create"
                  ? this.state.form_data.title
                  : null,
            }}
            edit={this.state.action !== "see" ? true : false}
            error={this.state.form_errors?.message_error_title}
          />
          <StringInput
            handleChangeTextInput={e => this.handleChangeTextInput(e)}
            data={{
              label: "text",
              title: "Texto",
              type: "textarea",
              value:
                this.state.action !== "create"
                  ? this.state.form_data.text
                  : null,
            }}
            edit={this.state.action !== "see" ? true : false}
            error={this.state.form_errors?.message_error_text}
          />
          {this.state.newsimages.map((newimage, indexPlaceimages) => {
            return (
              <FormGroup key={indexPlaceimages} className="row mb-4 ">
                <div className="row mb-1 ">
                  {" "}
                  {/*bg-secondary*/}
                  <Row className="mt-2">
                    <Label className="col-sm-2 col-form-label " htmlFor="rut">
                      Imagenes
                    </Label>
                    <Label className="col-sm-2 col-form-label " htmlFor="rut">
                      Nombre
                    </Label>
                    <Col className="col-sm-6 ">
                      <Input
                        type="text"
                        name="name"
                        data-testid="nameI"
                        // disabled={this.props.edit === false ? true : false}
                        disabled={this.state.action !== "see" ? false : true}
                        value={newimage.name}
                        placeholder="Escriba un nombre"
                        onChange={event =>
                          this.handleChangePlaceImage(event, indexPlaceimages)
                        }
                      />
                    </Col>
                    {this.state.newsimages.length - 1 === indexPlaceimages && (
                      <Col className="col-1 ">
                        <div className="d-grid">
                          <button
                            // disabled={this.props.edit === false ? true : false}
                            disabled={
                              this.state.action !== "see" ? false : true
                            }
                            className="btn btn-primary btn-block"
                            onClick={() => this.addPlaceImage(indexPlaceimages)}
                          >
                            <i className="fa fa-plus" aria-hidden="true"></i>
                          </button>
                        </div>
                      </Col>
                    )}
                    {this.state.newsimages.length > 1 && (
                      <Col className="col-1 ">
                        <div className="d-grid">
                          <button
                            disabled={
                              this.state.action !== "see" ? false : true
                            }
                            // disabled={this.props.edit === false ? true : false}
                            className="btn btn-danger btn-block"
                            onClick={() =>
                              this.removePlaceImage(indexPlaceimages)
                            }
                          >
                            <i className="fa fa-minus" aria-hidden="true"></i>
                          </button>
                        </div>
                      </Col>
                    )}
                  </Row>
                  <Row className="mt-2 mb-2">
                    <Label
                      className="col-sm-2 col-form-label"
                      htmlFor=""
                    ></Label>
                    <Label
                      className="col-sm-3 col-lg-2 col-form-label"
                      htmlFor="icon"
                    >
                      Posición
                    </Label>
                    <Col className="col-sm-6">
                      <Input
                        className="form-control"
                        disabled={this.state.action !== "see" ? false : true}
                        type="number"
                        name="pos"
                        id="pos"
                        value={newimage?.pos}
                        placeholder="Ingresa una posición"
                        onChange={event =>
                          this.handleChangePlaceImage(event, indexPlaceimages)
                        }
                      />
                    </Col>
                  </Row>
                  <Row className="mt-2 mb-2">
                    <Label
                      className="col-sm-2 col-form-label"
                      htmlFor=""
                    ></Label>
                    <Label
                      className="col-sm-3 col-lg-2 col-form-label"
                      htmlFor="icon"
                    >
                      Imagen
                    </Label>
                    <Col className="col-sm-6">
                      <Input
                        className="form-control"
                        disabled={this.state.action !== "see" ? false : true}
                        type="file"
                        name="icon"
                        id="icon"
                        accept="image/*"
                        value={newimage?.icon}
                        placeholder="Seleccionar una imagen"
                        onChange={event =>
                          this.handleChangePlaceImage(event, indexPlaceimages)
                        }
                      />
                    </Col>
                    <Col className="col-2">
                      {newimage && (
                        <div className="d-grid">
                          <img
                            style={{
                              width: "50px",
                              height: "50px",
                              objectFit: "cover",
                              objectPosition: "100% 20%",
                            }}
                            data-dz-thumbnail=""
                            height="200"
                            className="avatar-md rounded bg-light"
                            alt={newimage.icon?.name}
                            src={
                              newimage?.preview
                                ? newimage.preview
                                : API_URL + newimage.path
                            }
                          />
                        </div>
                      )}
                    </Col>
                  </Row>
                </div>
              </FormGroup>
            );
          })}
          {this.state.response_data && (
            <Row>
              <Col className="col">
                <div className="mt-3 d-grid">
                  <AlertUC response_data={this.state.response_data} />
                </div>
              </Col>
            </Row>
          )}
          {this.state.action !== "see" && (
            <Row>
              <Col className="col">
                <div className="mt-3 d-grid">
                  <SaveButton></SaveButton>
                </div>
              </Col>
            </Row>
          )}
        </AvForm>
      </React.Fragment>
    );
  }
}

export default withRouter(NewAction);
