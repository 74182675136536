/** @format */

import axios from "axios";

export const API_URL =
  process.env.REACT_APP_BUILD_VER === "prod"
    ? `https://api.procomercial.com/v1/`
    : `https://appovalle-api-dev.usercode.cl:1009/v1/`;


const token = localStorage.getItem("token");

const axiosApi = axios.create({
  baseURL: API_URL,
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
);

export async function get(url, config = {}) {
  // document.getElementById("main-content").classList.add("loading");
  return await axiosApi
    .get(
      url,
      { ...config },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then(response => {
      // document.getElementById("main-content").classList.remove("loading");
      // console.log("GET DATA: ", response.data);
      return response.data;
    })
    .catch(error => {
      // document.getElementById("main-content").classList.remove("loading");
      // console.log("ERROR: ", error.response);
      return error.response.data;
    });
}

export async function post(url, data, config = {}) {
  //   document.getElementById("main-content").classList.add("loading");
  return axiosApi
    .post(
      url,
      data,
      { ...config },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then(response => {
      //   document.getElementById("main-content").classList.remove("loading");
      console.log("POST DATA: ", response.data);
      return response.data;
    })
    .catch(error => {
      //   document.getElementById("main-content").classList.remove("loading");
      console.log("ERROR: ", error.response);
      return error.response.data;
    });
}

export async function put(url, data, config = {}) {
  // document.getElementById("main-content").classList.add("loading");
  return axiosApi
    .put( url,
      data,
      { ...config },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    .then(response => {
      // document.getElementById("main-content").classList.remove("loading");
      console.log("PUT DATA: ", response.data);
      return response.data;
    })
    .catch(error => {
      // document.getElementById("main-content").classList.remove("loading");
      console.log("ERROR: ", error.response);
      return error.response.data;
    });
}

export async function del(url, config = {}) {
  // document.getElementById("main-content").classList.add("loading");
  return await axiosApi
    .delete(url, { ...config }, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      })
    .then(response => {
      // document.getElementById("main-content").classList.remove("loading");
      console.log("DEL DATA: ", response.data);
      return response.data;
    })
    .catch(error => {
      // document.getElementById("main-content").classList.remove("loading");
      console.log("ERROR: ", error.response.message);
      return error.response.data;
    });
}
