/* eslint-disable react/prop-types */
import React, { Component } from "react";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import Select from "react-select";
import { AvField } from "availity-reactstrap-validation";
import { Col, FormGroup, Label } from "reactstrap";
import ErrorInput from "./ErrorInput";

class SelectInput extends Component {
  static propTypes = {
    //match: PropTypes.object.isRequired,
    //location: PropTypes.object.isRequired,
    //history: PropTypes.object.isRequired,
    handleChangeSelectInput: PropTypes.func,
    validateSelectInput: PropTypes.func,
    data: PropTypes.array,
    edit: PropTypes.bool,
    data: PropTypes.array,
    // error: PropTypes.array,
  };
  constructor(props) {
    super(props);
    console.log(this.props.data?.value);
  }

  render() {
    return (
      <React.Fragment>
        <FormGroup className="row mb-4">
          <Label
            className="col-sm-3 col-lg-2 col-form-label"
            htmlFor={this.props.data.label}
          >
            {this.props.data.title}
          </Label>
          {/* {this.props.title ? (
          ) : null} */}

          <Col className="col-sm-9 col-lg-10">
            <Select
              className={
                this.props.error ? "border border-danger rounded" : null
              }
              name={this.props.data.label}
              isMulti={this.props.data.isMulti}
              onChange={e =>
                this.props.handleChangeSelectInput(e, this.props.data.label)
              }
              onBlur={e =>
                this.props.validateSelectInput(
                  e,
                  this.props.data.label,
                  this.props.data.value
                )
              }
              inputId={this.props.data.label}
              options={this.props.data.options}
              value={
                this.props.data?.value && this.props.data.isMulti
                  ? this.props.data.value?.map(val =>
                      this.props.data.options?.find(item => item.value === val)
                    )
                  : this.props.data.options?.find(
                      item => item.value === this.props.data?.value
                    )
                // this.props.data.options?.find(
                //     item => item.value === this.props.data.value
                //   )
              }
              errorMessage={`Ingrese ${this.props.data.title}`}
              placeholder={`Ingrese ${this.props.data.title}`}
              //Invested securities
              isDisabled={
                this.props.edit === true || this.props.edit === undefined
                  ? false
                  : true
              }
              //Normal values
              required={
                this.props.data.required === true ||
                this.props.data.required === undefined
                  ? true
                  : false
              }
            />
            {this.props.error && <ErrorInput error={this.props.error} />}
          </Col>
        </FormGroup>
      </React.Fragment>
    );
  }
}

export default withRouter(SelectInput);
