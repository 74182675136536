/* eslint-disable react/prop-types */
import React, { Component } from "react";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { AvField } from "availity-reactstrap-validation";

class EmailInput extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        <AvField
          name="email"
          label="Email"
          onChange={e => this.props.handleChangeTextInput(e)}
          // value="admin@themesbrand.com"
          className="form-control"
          errorMessage="Ingrese email"
          placeholder="Ingrese su email"
          type="email"
          required
        />
      </React.Fragment>
    );
  }
}

export default withRouter(EmailInput);
